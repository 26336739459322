<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
    </div>

    <hr class="my-3">

    <ListCategory
      :title="title"
      :deviceType="deviceType"
      :templateType="templateType"
      :group="group">
    </ListCategory>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import ListCategory from './ListCategory.vue'

export default {
  components: {
    ListCategory
  },
  mixins: [retainMixin],
  props: {
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Device${this.$form.capitalize(this.deviceType)}List`
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
}
</script>

<style lang="css" scoped>
</style>
